import {GET_FORM_SUCCESS, SHOW_FORM_LOADING, GET_FORM_ERROR, FIELD_CHANGED, GET_FORM_ROW} from "../constants/Form";
import {fieldChanged} from "../actions/Form";

const initState = {
  form: {},
  formLoading: false,
  selectedFormRow: null,
  fieldChanged: 0,
}

const form = (state=initState, action) => {
  switch (action.type) {
    case GET_FORM_SUCCESS:
      return {
        formLoading: false,
        form: action.payload,
        fieldChanged: 0
      }
    case GET_FORM_ERROR:
      return {
        ...state,
        formLoading: false,
      }
    case SHOW_FORM_LOADING: {
      return {
        ...state,
        formLoading: true
      }
    }
    case FIELD_CHANGED: {
      return {
        ...state,
        fieldChanged: state.fieldChanged + 1
      }
    }
    case GET_FORM_ROW: {
      console.log(action.payload)
      return {
        ...state,
        selectedFormRow: action.payload
      }
    }
    default:
      return state;
  }
}

export default form;