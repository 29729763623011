import React from 'react';
import { Widget } from './index';
import {Button, Col, Divider, message, Table, Tooltip} from "antd";
import Card from "antd/es/card";
import {ApiService, handlerError} from "../../services/api/ApiService";
import {responseExportation} from "../../services/api/exportation/transform/ExportationTransform";
import { useHistory } from "react-router-dom";
import {PlusOutlined} from "@ant-design/icons";

import * as AntdIcons from '@ant-design/icons';
import Icon from "../../components/util-components/Icon";
import {transformColumn} from "../../utils/TransformActionTable";


export class WidgetTable extends Widget {

  constructor(obj={}){
    super(obj);

    this.headers = obj.headers;
    this.rows = obj.rows;
  }

  getComponent() {
    return () => {

      const transformDataSource = (rows=this.rows) => {
        return rows
      }

      return (
        <Card title={this.title}>
          <Table
            columns={transformColumn(this.headers)}
            dataSource={transformDataSource()}
            scroll={{ x: 1 }}
            pagination={this.pagination}
            bordered={this.bordered}
            size={this.tableSize}
          />
        </Card>
      );
    }
  }

  transformToWidget = (data) => {
    this.title = data.title;
    this.type = data.type;
    this.size = data.size;
    this.value = data.value;
    this.headers = data.header;
    this.tableSize = data.table_size ?? "middle";
    this.rows = data.rows;
    this.bordered = data.bordered ?? false;
    this.pagination = data.pagination || data.pagination==undefined ? {hideOnSinglePage: true, defaultPageSize: 10} : false
    return this;
  }

}