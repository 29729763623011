import {Widget} from "./index";
import React from "react";
import DonutChartWidget from "../../components/shared-components/DonutChartWidget";
import Card from "antd/es/card";

export class WidgetChartPie extends Widget {

  constructor(obj = {}) {
    super(obj);

    this.value = obj.value;
    this.series = obj.series;
    this.labels = obj.labels;
    this.height = obj.height ? obj.height : 400;
    this.customOptions = obj.customOptions;
  }

  getComponent() {
    return () => (
      <DonutChartWidget
        title={this.title}
        series={this.transformSeries()}
        labels={this.labels}
        height={this.height}
        customOptions={this.customOptions}
      />
    );
  }

  transformToWidget = (data) => {
    this.title = data.title;
    this.type = data.type;
    this.size = data.size;
    this.value = data.value;
    this.series = data.series;
    this.labels = data.labels;
    this.height = data.height ? data.height : 400
    this.customOptions = data.customOptions;
    return this;
  }

  transformSeries = (series = this.series) => {
    return series.map(value => {
      if(value === null){
        value = 0;
      }
      return value;
    })
  }
}