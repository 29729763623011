import React, {useEffect, useMemo, useState} from "react";
import { Form, Row, Col} from 'antd';
import {ROW_GUTTER} from "../../../constants/ThemeConstant";
import Card from "antd/es/card";
import utils from "../../../utils";
import { useHistory } from "react-router-dom";
import { fieldChanged } from "../../../services/actions/Form";
import { connect } from "react-redux";

const FormActions = (({title, fields, buttons, form: formProps, fieldChanged, changed}) => {

  let history = useHistory();

  const form = formProps ?? Form.useForm()[0];
  const data = utils.getJsonSearch();

  useEffect(() => {
    form.setFieldsValue(utils.parseValueToField(data, fields))
    setTimeout(() => {
      fieldChanged()
    }, 10);
  }, [])

  return (
    <Card title={title}>
      <div className="mt-4">
        <Form
          name="basicInformation"
          layout="vertical"
          form={form}
          autoComplete="off"
          // onFinishFailed={onFinishFailed}
          onChange={fieldChanged}
        >
          <Row>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Row gutter={ROW_GUTTER}>
                {fields?.map((field) => (
                  <RenderField field={field} form={form} changed={field.needRefresh ? changed : 0} fieldChanged={fieldChanged}/>
                ))}
              </Row>
              <div>
              {buttons?.map((button) => (
                button.getComponent(form, history)
              ))}
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </Card>
  );
});

const RenderField = ({field, form, changed, fieldChanged}) => {
  const FieldComponent = useMemo(() => field.getComponent(form, fieldChanged), [field, changed]);

  return <FieldComponent/>
}


const mapStateToProps = (state, ownProps) => ({
  changed: state.form.fieldChanged
})

export const FormWithAction = connect(mapStateToProps, { fieldChanged })(FormActions)

