import { combineReducers } from 'redux';
import Auth from './Auth';
import Dashboard from './Dashboard';
import Patient from './Patient';
import Chat from './Chat'
import Theme from './Theme';
import Form from './Form'

const reducers = combineReducers({
    theme: Theme,
    auth: Auth,
    dashboard: Dashboard,
    patient: Patient,
    chat: Chat,
    form: Form
});

export default reducers;