import React, { useState } from 'react'
import {Spin, Select, Form, Col} from "antd";
import {Field} from "./index";
import DashboardService from "../../services/api/dashboard";
import Utils from "../../utils";

const { Option } = Select;

export class FieldRemoteSearch extends Field {

  constructor(obj={}) {
    super(obj);
  }

  getComponent (form) {

    return () => {
      const [ data, setData ] = useState([]);
      const [ fetching, setFetching] = useState(false);

      const onSearch = async (value) => {
        const idSearch = this.idSearch;
        setData([]);
        setFetching(true);
        const response = await DashboardService.getSearchInput({idSearch, value});

        const data = response.options;

        setData(data);
        setFetching(false);

      };


      const handleChange = value => {
        // setData2([]);
        // setFetching(false);
      };

      return (
        <Col className={!Utils.evaluateFormula(this.condition, form) ? "display-none" : "visible"} xs={24} sm={24} md={this.size}>

        <Form.Item
          label={this.label}
          name={this.name}
          initialValue={this.value}
        >
          <Select
            allowClear
            placeholder="Seleccionar"
            labelInValue
            showSearch
            mode={this.mode}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            filterOption={false}
            onSearch={onSearch}
            onChange={handleChange}
            style={{ width: '100%' }}
          >
            {data?.map(d => (
              <Option key={d.key}>{d.label}</Option>
            ))}
          </Select>
        </Form.Item>
        </Col>);
    }
  }

  transformToField = (data) => {
    this.name = data.name;
    this.label = data.label;
    this.type = data.type;
    this.size = data.size ?? this.size;
    this.idSearch = data.idSearch;
    this.mode = data.mode ?? 'multiple'
    this.condition = data.condition ?? true;
    this.value = this.transformStringToObject(data.value);

    return this;
  }

  transformValueToField = (data) => {
    let result;
    if(typeof data == "string" && data.substring(0, 7) == "base64-") {
      //Uso un substring ya que he puesto una etiqueta para diferenciar el tipo al enviar la petición
      const base64ToString = Buffer.from(data.substring(7), "base64").toString();
      result = JSON.parse(base64ToString)
    }
    else{
      if(typeof data == "string")
        result = this.transformStringToObject(data)
      else
        result = data
    }
    return result
  }

  transformStringToObject(string){
    let result
    if (string == "" || string == undefined)
      result = undefined
    else {
      const arrayOfSelected = string?.split(",")
      result = arrayOfSelected.map((objectString) => {
        const arrayString = objectString?.split(" - ")
        return {
          label: arrayString[1],
          key: arrayString[0]
        }
      })

    }
    return result
  }

}
