import React from 'react'
import {Field} from "./index";
import {Col} from "antd";


export class FieldHeader extends Field {

  constructor(obj={}) {
    super(obj);
  }

  getComponent () {
    return () => (
      <Col xs={24} sm={24} md={this.size}>
        <div>
          <h4>{this.value}</h4>

        </div>
      </Col>);

  }

  transformToField = (data) => {
    this.type = data.type;
    this.size = 24;
    this.value = data.value;
    return this;
  }
}