import {useState} from "react";
import {Badge} from "antd";
import React from "react";
import Menu from "antd/es/menu";
import {MailOutlined} from '@ant-design/icons';

import { useHistory } from "react-router-dom";
import utils from "../../utils";



export const NavMessages = () => {
  let history = useHistory();

  const redirectToChat = () => {
    history.push(utils.getPathWithSearch(utils.getPathWithSearch(utils.getInitRootProject()+'/chat')));
  }
  return (
      <Menu mode="horizontal">
        <Menu.Item onClick={redirectToChat} >
          <Badge count={0}>
            <MailOutlined className="nav-icon mx-auto" type="bell" />
          </Badge>
        </Menu.Item>
      </Menu>
  )
}


export default NavMessages;