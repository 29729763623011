import { Widget } from './index';
import React from "react";
import Card from "antd/es/card";

export class WidgetIframe extends Widget {

  constructor(obj={}){
    super(obj);

    this.value = obj.value;
  }

  getComponent() {
    return () => (
      <Card title={this.title}>
        <iframe src={this.value} style={{height: '80vh'}} frameBorder="0" width="100%" allowFullScreen></iframe>
      </Card>
    );
  }

  transformToWidget = (data) => {
    this.title = data.title;
    this.type = data.type;
    this.size = data.size;
    this.value = data.value;
    return this;
  }
}