import {GET_DASHBOARD, GET_DASHBOARD_SUCCESS, SHOW_DASHBOARD_LOADING, GET_DASHBOARD_ERROR} from "../constants/Dashboard";

export const getDashboard = (id, fake) => {
  return {
    type: GET_DASHBOARD,
    payload: id,
    fake
  };
};

export const getDashboardSuccess = (dashboard) => {
  return {
    type: GET_DASHBOARD_SUCCESS,
    payload: dashboard
  };
};
export const getDashboardError = () => {
  return {
    type: GET_DASHBOARD_ERROR,
  };
};

export const showDashboardLoading = () => {
  return {
    type: SHOW_DASHBOARD_LOADING,
  };
};