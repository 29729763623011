import moment from "moment";

export const getSendData = (data) => {
  return ({
    tipo: "OPERACION_15-2",
    destino: data.to,
    mensaje: data.text
  });
}

export const readData = (data) => {
  return ({
    tipo: "OPERACION_15-3",
    destino: data,
  });
}

export const responseChat = ({ data = { chat: {} } }) => {
  const { chat } = data;
  let aux = chat.map(transformChat)
  return (aux.sort((a, b) => (a.order < b.order) ? 1 : -1))
}


const transformChat = (chat) => ({
  id: chat.id_usuario_chat,
  name: chat.usuario_chat,
  unread: getUnreadMessages(chat.mensajes, chat.usuario_chat),
  time: getTimeLastMessage(chat.mensajes),
  order: getTimeForOrder(chat.mensajes),
  msg: chat.mensajes.map(transformMessages)
})


const transformMessages = (message) => ({
  avatar: "/img/avatars/thumb-7.jpg",
  text: message.mensaje,
  from: message.id_usuario_origen,
  time: message.fecha_envio+" "+message.hora_envio,
  msgType: "text"
})

const getUnreadMessages = (messages, to) => {
  return messages.filter((message) => message.leido == "false" && message.id_usuario_origen == to).length
}

const getTimeLastMessage = (messages) => {
  let result = "";
  if(messages.length>0) {
    result = messages[messages.length - 1].fecha_envio;
    const aux = moment(result, "DD-MM-YYYY").format("DD-MM-YYYY");
    if(aux == moment().format("DD-MM-YYYY"))
      result = messages[messages.length - 1].hora_envio;
  }
  return result
}

const getTimeForOrder = (messages) => {
  let result = "";
  if(messages.length>0) {
    result = moment(messages[messages.length - 1].fecha_envio+" "+messages[messages.length - 1].hora_envio, "DD-MM-YYYY h:m").format("YYYY-MM-DD h:m");
  }
  return result
}