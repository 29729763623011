import React from 'react';
import { Widget } from './index';
import Card from "antd/es/card";
import {TableWithChart} from "../../components/shared-components/TableWithChart";
import moment from "moment";
import TableForm from "../../components/shared-components/TableForm";
import {transformColumnForm, transformFieldToColumn} from "../../utils/TransformActionTable";

export class WidgetTableForm extends Widget {

  constructor(obj={}){
    super(obj);

    this.headers = obj.headers;
    this.rows = obj.rows;
  }

  getComponent() {
    return () => (
      <Card title={this.title}>
        <TableForm id={this.id} title={this.title} columns={this.headers} rows={this.rows.map(transformColumnForm)} fields={this.fields}/>
      </Card>
    );
  }



  transformToWidget = (data) => {
    console.log(data)
    this.title = data.title;
    this.type = data.type;
    this.size = data.size;
    this.value = data.value;
    this.fields = data.fields;
    this.headers = data.headers;
    this.rows = data.rows;
    this.id = data.id;
    return this;
  }

}