import React from 'react'
import {Col, Form, Select} from "antd";
import {Field} from "./index";
import Utils from "../../utils";
import {fieldChanged} from "../../services/actions/Form";
import {FieldBase} from "./components/FieldBase";

const { Option } = Select;


export class FieldSelect extends Field {

  constructor(obj={}) {
    super(obj);
  }


  getComponent (form, fieldChanged) {
    return () => (
      <FieldBase condition={this.condition} form={form} size={this.size} label={this.label} name={this.name} value={this.value} help={this.help}>

        <Select
          allowClear
          showSearch
          placeholder="Seleccionar"
          optionFilterProp="children"
          onChange={fieldChanged}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {this.options?.map(option => <Option key={option.key}>{option.label}</Option>)}
        </Select>
      </FieldBase>);
  }

  transformToField = (data) => {
    this.options = data.options;
    this.name = data.name;
    this.label = data.label;
    this.type = data.type;
    this.size = data.size ?? this.size;
    this.value = data.value;
    this.disabled = data.disabled ?? false;
    this.condition = data.condition ?? true;
    this.help = data.help;

    return this;
  }
}