import React from 'react'
import {Col, Form, Input, Tooltip} from "antd";
import {Field} from "./index";
import Utils from "../../utils";
import {QuestionCircleOutlined} from "@ant-design/icons";
import {LabelWithTooltip} from "./components/LabelWithTooltip";
import {FieldBase} from "./components/FieldBase";

export class FieldText extends Field {

  constructor(obj={}) {
    super(obj);
  }



  getComponent (form) {
    return () => (
      <FieldBase condition={this.condition} form={form} size={this.size} label={this.label} name={this.name} value={this.value} help={this.help}>
        <Input
          disabled={this.disabled}
          placeholder=""

        />
      </FieldBase>);
  }

  transformToField = (data) => {
    this.name = data.name;
    this.label = data.label;
    this.type = data.type;
    this.size = data.size ?? 6;
    this.value = data.value ?? data.defaultValue ?? "";
    this.disabled = data.disabled ?? false;
    this.condition = data.condition ?? true;
    this.help = data.help;
    return this;
  }
}