import {GET_DASHBOARD_SUCCESS, SHOW_DASHBOARD_LOADING, GET_DASHBOARD_ERROR} from "../constants/Dashboard";

const initState = {
  dashboard: {},
  dashboardLoading: false
}

const dashboard = (state=initState, action) => {
  switch (action.type) {
    case GET_DASHBOARD_SUCCESS:
      return {
        dashboardLoading: false,
        dashboard: action.payload
      }
    case GET_DASHBOARD_ERROR:
      return {
        ...state,
        dashboardLoading: false,
      }
    case SHOW_DASHBOARD_LOADING: {
      return {
        ...state,
        dashboardLoading: true
      }
    }
    default:
      return state;
  }
}

export default dashboard;