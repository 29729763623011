import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {GET_CHAT} from "../constants/Chat";
import {getChatSuccess} from "../actions/Chat";
import ChatService from "../api/chat";
import {GET_SETTING} from "../constants/Setting";
import SettingService from "../api/setting";

export function* requestSetting() {

	yield takeEvery(GET_SETTING, function* () {
		try {
			const response = yield call(SettingService.get);
			if (response.message) {
				// TODO implement generic error dashboard
				// yield put(showErrorMessage(response.message));
			} else {
				yield put(getChatSuccess(response));
			}
		} catch (error) {
			// TODO implement generic error dashboard
			// yield put(showErrorMessage('api.error.login'));
		}
	})
}

export default function* rootSaga() {
  yield all([
		fork(requestSetting)
  ]);
}
