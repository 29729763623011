import React, {useEffect, useMemo, useState} from "react";
import { Form, Row, Col} from 'antd';
import {ROW_GUTTER} from "../../../constants/ThemeConstant";
import Card from "antd/es/card";
import utils from "../../../utils";
import { useHistory } from "react-router-dom";
import {fieldChanged} from "../../../services/actions/Form";
import { connect } from "react-redux";
import Button from "antd/es/button";

const styleFooter = {
  position: 'absolute',
  bottom: 0,
  width: '100%',
  borderTop: '1px solid #e8e8e8',
  padding: '10px 16px',
  textAlign: 'right',
  left: 0,
  background: '#fff',
  borderRadius: '0 0 4px 4px',
}

const FormActions = (({title, fields, buttons, fieldChanged, changed, selectedRow, save, removeLine, onClose, visible}) => {

  let history = useHistory();

  const form = Form.useForm()[0];


  useEffect(() => {
    form.resetFields();
    if(selectedRow)
      form.setFieldsValue(utils.parseValueToField(selectedRow, fields))
    fieldChanged()
  }, [selectedRow, visible])

  return (
    <div>
    <Form
      name="basicInformation"
      layout="vertical"
      form={form}
      autoComplete="off"
      // onFinishFailed={onFinishFailed}
      onChange={fieldChanged}
    >
      <Card title={title}>
        <div className="mt-4">
          <Row>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Row gutter={ROW_GUTTER}>
                {fields?.map((field) => (
                  <RenderField field={field} form={form} changed={field.needRefresh ? changed : 0} fieldChanged={fieldChanged}/>
                ))}
              </Row>
              <div>
                {buttons?.map((button) => (
                  button.getComponent(form, history)
                ))}
              </div>
            </Col>
          </Row>
        </div>
      </Card>
    </Form>

      <div
        style={styleFooter}
      >
        <Button  type="primary" danger onClick={
          () => {removeLine(selectedRow?.key);onClose();}
        }
                style={{
            float: "left",
          }}
        >
          Eliminar
        </Button>
        <Button onClick={onClose}
                style={{
            marginRight: 8,
          }}
        >
          Cancelar
        </Button>
        <Button type="primary"
          onClick={() => {
            save({key: selectedRow?.key, ...form.getFieldsValue()});
            onClose();
          }}>
          Guardar
        </Button>
      </div>
    </div>
  );
});

const RenderField = ({field, form, changed, fieldChanged}) => {
  const FieldComponent = useMemo(() => field.getComponent(form, fieldChanged), [field, changed]);

  return <FieldComponent/>
}


const mapStateToProps = (state, ownProps) => ({
  changed: state.form.fieldChanged,
  selectedRow: state.form.selectedFormRow
})

export const DrawerForm = connect(mapStateToProps, { fieldChanged })(FormActions)

