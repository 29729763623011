import {WidgetText} from "../models/widgets/Text";
import {WidgetTable} from "../models/widgets/Table";
import {WidgetTableSearch} from "../models/widgets/TableSearch";
import {WidgetTableWithChart} from "../models/widgets/TableWithChart";
import {WidgetStatistic} from "../models/widgets/Statistic";
import {WidgetStatisticWithChart} from "../models/widgets/StatisticWithChart";
import {WidgetGoal} from "../models/widgets/Goal";
import {WidgetChart} from "../models/widgets/Chart";
import {WidgetChartPie} from "../models/widgets/ChartPie";
import {WidgetCollapse} from "../models/widgets/Collapse";
import {WidgetTree} from "../models/widgets/Tree";
import {WidgetMap} from "../models/widgets/Map";
import {WidgetFormAction} from "../models/widgets/FormAction";
import {Widget} from "../models/widgets";
import {FieldText} from "../models/fields/Text";
import {FieldTextArea} from "../models/fields/TextArea";
import {FieldNumber} from "../models/fields/Number";
import {FieldMultiselect} from "../models/fields/Multiselect";
import {FieldSelect} from "../models/fields/Select";
import {FieldTreeSelect} from "../models/fields/TreeSelect";
import {FieldRadio} from "../models/fields/Radio";
import {FieldDate} from "../models/fields/Date";
import {FieldRangeDate} from "../models/fields/RangeDate";
import {FieldMonthDate} from "../models/fields/MonthDate";
import {FieldRemoteSearch} from "../models/fields/RemoteSearch";
import {FieldBlank} from "../models/fields/Blank";
import {FieldLine} from "../models/fields/Line";
import {Field} from "../models/fields";
import {CustomButton} from "../models/buttons";
import {Col, Row, Tabs} from "antd";
import React, {useMemo} from "react";
import {FieldCalculated} from "../models/fields/Calculated";
import {WidgetTableForm} from "../models/widgets/TableForm";
import {transformFieldToColumn} from "./TransformActionTable";
import {WidgetIframe} from "../models/widgets/Iframe";
import {FieldFile} from "../models/fields/File";
import {FieldHeader} from "../models/fields/Header";

const { TabPane } = Tabs;

export const transformRow = (data) => {
  switch (data.type) {
    case "tab":
      return {
        type: data.type,
        tabs: data.tabs.map(transformTab)
      }
    case "row":
      return ({
        type: data.type,
        columns: data.columns.map(transformColumn)
      })
  }
};

export const transformColumn = (data) => {
  if(data.type === 'column') {
    return ({
      type: data.type,
      size: data.size,
      rows: data.rows.map(transformRow)
    });
  }
  else {
     return transformWidget(data);
  }
}

export const transformTab = (data) => {
  return ({
    title: data.title,
    rows: data.rows?.map(transformRow)
  })
};
export const transformWidget = (data) => {
  switch (data.type) {
    case 'text':
      return new WidgetText().transformToWidget(data);
    case 'table':
      return new WidgetTable().transformToWidget(data);
    case 'table-search':
      return new WidgetTableSearch().transformToWidget(data);
    case 'table-chart':
      return new WidgetTableWithChart().transformToWidget(data);
    case 'table-form':
      data.headers = data.fields.map(transformFieldToColumn);
      data.fields = data.fields.map(transformField);
      return new WidgetTableForm().transformToWidget(data);
    case 'statistic':
      return new WidgetStatistic().transformToWidget(data);
    case 'statistic-chart':
      return new WidgetStatisticWithChart().transformToWidget(data);
    case 'goal':
      return new WidgetGoal().transformToWidget(data);
    case 'chart':
      return new WidgetChart().transformToWidget(data);
    case 'chart-pie':
      return new WidgetChartPie().transformToWidget(data);
    case 'collapse':
      return new WidgetCollapse().transformToWidget(data);
    case 'tree':
      return new WidgetTree().transformToWidget(data);
    case 'iframe':
      return new WidgetIframe().transformToWidget(data);
    case 'map':
      return new WidgetMap().transformToWidget(data);
    case 'form-action': case 'section':
      data.fields = data.fields.map(transformField);
      data.buttons = data.buttons?.map(transformButton);
      return new WidgetFormAction().transformToWidget(data);
    default:
      return new Widget().transformToWidget(data);
  }
}

// ---------------- Form ----------------

export const transformField = (data) => {
  switch (data.type) {
    case 'text':
      return new FieldText().transformToField(data);
    case 'textarea':
      return new FieldTextArea().transformToField(data);
    case 'number':
      return new FieldNumber().transformToField(data);
    case 'multiselect':
      return new FieldMultiselect().transformToField(data);
    case 'select':
      return new FieldSelect().transformToField(data);
    case 'treeselect':
      return new FieldTreeSelect().transformToField(data);
    case 'radio':
      return new FieldRadio().transformToField(data);
    case 'date':
      return new FieldDate().transformToField(data);
    case 'rangedate':
      return new FieldRangeDate().transformToField(data);
    case 'monthdate':
      return new FieldMonthDate().transformToField(data);
    case 'remotesearch':
      return new FieldRemoteSearch().transformToField(data);
    case 'calculated':
      return new FieldCalculated().transformToField(data);
    case 'file':
      return new FieldFile().transformToField(data);
    case 'blank':
      return new FieldBlank().transformToField(data);
    case 'line':
      return new FieldLine().transformToField(data);
    case 'header':
      return new FieldHeader().transformToField(data);
    default:
      return new Field().transformToField(data);
  }
}

export const transformButton = (data) => {
  switch (data.type) {
    default:
      return new CustomButton().transformToField(data);
  }
}


// --------------- Builder Dashboard  -------------------

export const builderDashboard = (element, formData) => {
  if(element.type !== 'column' && element.type !== 'row' && element.type !== 'tab'){
    return (
      <Col xs={24} sm={24} md={element.size}>
        <RenderWidget element={element} formData={formData}/>
      </Col>
    );
  }
  if(element.type === 'column') {
    return (
      <Col xs={24} sm={24} md={element.size}>
        {element.rows.map(element => builderDashboard(element, formData))}
      </Col>
    );
  }
  if(element.type === 'row') {
    return (
      <Row gutter={16}>
        {element.columns.map(element => builderDashboard(element, formData))}
      </Row>
    );
  }
  if(element.type === 'tab') {
    return (
      <Col xs={24} sm={24} md={element.size}>
        <Tabs>
          {element.tabs.map( tab => <TabPane tab={tab.title} key={tab.title}>
              {tab.rows?.map(element => builderDashboard(element, formData))}
            </TabPane>
          )}
        </Tabs>
      </Col>
    );
  }
}

const RenderWidget = ({element, formData}) => {
  const WidgetComponent = useMemo(() => element.getComponent({formData}), [element]);

  return <WidgetComponent/>
}
