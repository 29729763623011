import { Widget } from './index';
import React from "react";
import Card from "antd/es/card";
import { Tree } from 'antd';
const { DirectoryTree } = Tree;

export class WidgetTree extends Widget {

  constructor(obj={}){
    super(obj);

    this.value = obj.value;
  }

  getComponent() {
    return () => (
      <Card title={this.title}>
        <DirectoryTree
          defaultExpandAll
          treeData={this.treeData}
        />
      </Card>
    );
  }

  transformToWidget = (data) => {
    this.title = data.title;
    this.treeData =[
      {
        title: 'parent 0',
        key: '0-0',
        children: [
          {
            title: 'leaf 0-0',
            key: '0-0-0',
            isLeaf: true,
          },
          {
            title: 'leaf 0-1',
            key: '0-0-1',
            isLeaf: true,
          },
        ],
      },
      {
        title: 'parent 1',
        key: '0-1',
        children: [
          {
            title: 'leaf 1-0',
            key: '0-1-0',
            isLeaf: true,
          },
          {
            title: 'leaf 1-1',
            key: '0-1-1',
            isLeaf: true,
          },
        ],
      },
    ];
    return this;
  }
}