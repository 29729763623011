import React from 'react'
import {Col, Form, Input} from "antd";
import Utils from "../../utils";

export class Field {

  constructor(obj={}) {
    this.name = obj.name;
    this.type = obj.type ?? 'text';
    this.label = obj.label;
    this.size = obj.size ?? 6;
  }

  getComponent () {
    return () => (
      <Col xs={24} sm={24} md={this.size}>

      <Form.Item
        label={this.label}
        name={this.name}
      >
        <Input />
      </Form.Item>
      </Col>);
  }

  transformToField = (data) => {
    this.name = data.name;
    this.label = "'Field no implement'";
    this.size = data.size ?? this.size;
    return this;
  }

  transformValueToField = (data) => data
}