import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import { Menu, Layout } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined, SearchOutlined } from '@ant-design/icons';
import Logo from './Logo';
import NavPanel from './NavPanel';
import NavSearch  from './NavSearch';
import SearchInput from './NavSearch/SearchInput.js'
import { toggleCollapsedNav, onMobileNavToggle } from 'services/actions/Theme';
import { NAV_TYPE_TOP, SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_WIDTH } from 'constants/ThemeConstant';
import utils from 'utils'
import NavProfile from "./NavProfile";
import NavNotification from "./NavNotification";
import NavMessages from "./NavMessages";

const { Header } = Layout;

export const HeaderNav = props => {
  const { navCollapsed, mobileNav, navType, headerNavColor, toggleCollapsedNav, onMobileNavToggle, isMobile, currentPatient, project, auth } = props;
  const [searchActive, setSearchActive] = useState(false)
  const [projectAux, setProjectAux] = useState(project)

  useEffect(() => {
    setProjectAux(auth.projects.find(o => o.id === utils.getIdProject() && o.hospital.id === utils.getIdHospital()))
  }, [project, utils.getIdHospital()])

  const onSearchActive = () => {
    setSearchActive(true)
  }

  const onSearchClose = () => {
    setSearchActive(false)
  }

  const onToggle = () => {
    if(!isMobile) {
      toggleCollapsedNav(!navCollapsed)
    } else {
      onMobileNavToggle(!mobileNav)
    }
  }

  const isNavTop = navType === NAV_TYPE_TOP ? true : false
  const mode = utils.getColorContrast(headerNavColor)
  const getNavWidth = () => {
    if(isNavTop || isMobile) {
      return '0px'
    }
    if(navCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`
    } else {
      return `${SIDE_NAV_WIDTH}px`
    }
  }
  return (
    <Header className={`app-header ${mode}`} style={{backgroundColor: headerNavColor}}>
      <div className={`app-header-wrapper ${isNavTop ? 'layout-top-nav' : ''}`}>
        <Logo logoType={mode} project={projectAux}/>
        <div className="nav" style={{width: `calc(100% - ${getNavWidth()})`}}>
          <div className="nav-left">
            <Menu mode="horizontal">
              {
                isNavTop && !isMobile ?
                null
                :
                <Menu.Item key="0" onClick={() => {onToggle()}}>
                  {navCollapsed || isMobile ? <MenuUnfoldOutlined className="nav-icon" /> : <MenuFoldOutlined className="nav-icon" />}
                </Menu.Item>
              }
              {
                isMobile ?
                  <Menu.Item key="1" onClick={() => {onSearchActive()}}>
                    {
                      projectAux?.searchBar ? <SearchOutlined/> : null
                    }
                  </Menu.Item>
                  :
                  <Menu.Item key="1" style={{cursor: 'auto'}}>
                    {
                      projectAux?.searchBar ? <SearchInput currentPatient={currentPatient} mode={mode} isMobile={isMobile}/> :null
                    }

                  </Menu.Item>
              }
            </Menu>
          </div>
          <div className="nav-right">
            <NavMessages />
            <NavNotification />
            <NavProfile />
            {/*<NavPanel />*/}
          </div>
          <NavSearch active={searchActive} close={onSearchClose} />
        </div>
      </div>
    </Header>
  )
}

const mapStateToProps = ({ theme, patient, auth }) => {
  const currentPatient = patient.patient;
  const { navCollapsed, navType, headerNavColor, mobileNav } =  theme;
  const project= auth.projects.find(o => o.id === utils.getIdProject() && o.hospital.id === utils.getIdHospital())
  return { navCollapsed, navType, headerNavColor, mobileNav, currentPatient, project, auth }
};

export default connect(mapStateToProps, {toggleCollapsedNav, onMobileNavToggle})(HeaderNav);