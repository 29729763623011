import React from 'react';
import { Widget } from './index';
import Card from "antd/es/card";
import {TableWithChart} from "../../components/shared-components/TableWithChart";
import moment from "moment";

export class WidgetTableWithChart extends Widget {

  constructor(obj={}){
    super(obj);

    this.headers = obj.headers;
    this.rows = obj.rows;
  }

  getComponent() {
    return () => (
      <Card title={this.title}>
        <TableWithChart
          data={this.transformDataSource()} columns={this.transformColumn()}
        />
      </Card>
    );
  }

  transformColumn(headers=this.headers) {
    return headers.map(o => ({
      title: o.title,
      dataIndex: o.dataIndex,
      key: o.dataIndex,
    }))
  }

  transformDataSource(rows=this.rows) {
    return rows.map( o => {
      o.date = moment.utc(o.date).format("YYYY-MM-DD")
      return o;
    })
  }

  transformToWidget = (data) => {
    this.title = data.title;
    this.type = data.type;
    this.size = data.size;
    this.value = data.value;
    this.headers = data.header;
    this.rows = data.rows;
    return this;
  }

}