import {Widget} from "./index";
import React from "react";
import StatisticWidget from "../../components/shared-components/StatisticWidget";
import ApexChart from "react-apexcharts";
import { apexLineChartDefaultOption, COLOR_2 } from 'constants/ChartConstant';

const memberChartOption = {
  ...apexLineChartDefaultOption,
  ...{
    xaxis: {
      categories: ['a', 'b', 'c']
    },
    chart: {
      sparkline: {
        enabled: true,
      }
    },
    colors: [COLOR_2],
  }
}

export class WidgetStatisticWithChart extends Widget {

  constructor(obj = {}) {
    super(obj);
    this.value = obj.value;
    this.series = obj.series;
    this.categories = obj.categories;
    this.subtitle = obj.subtitle;
    this.status = obj.status;
    this.height = obj.height ? obj.height : 145;

  }

  getComponent() {
    return () => (
      <StatisticWidget
        title={
          <ApexChart
            options={
              {
                ...memberChartOption,
                ...this.categories
              }
            }
            series={this.series}
            height={this.height}
          />
        }
        value={this.value}
        status={this.status}
        subtitle={this.subtitle}
      />
    );
  }

  transformToWidget = (data) => {
    this.title = data.title;
    this.type = data.type;
    this.size = data.size;
    this.height = data.height ? data.height : 145
    this.value = data.value;
    this.series = data.series;
    this.categories = {
      xaxis: {
        categories: data.categories
      }
    }
    this.subtitle = data.subtitle;
    this.status = data.status;
    return this;
  }
}