import React from 'react'
import { APP_NAME } from 'configs/AppConfig';
import {connect} from "react-redux";
import utils from "../../utils";


const Footer = ({project}) => {
	return (
		<footer className="footer">
			<span>Copyright  &copy;  {`${new Date().getFullYear()}`}
				<span className="font-weight-semibold">
					{` ${project?.hospital?.name ?? APP_NAME} `}
				</span>
				All rights reserved.
			</span>
			<div>
				<a className="text-gray" href="/#" onClick={e => e.preventDefault()}>Term & Conditions</a>
				<span className="mx-2 text-muted"> | </span>
				<a className="text-gray" href="/#" onClick={e => e.preventDefault()}>Privacy & Policy</a>
			</div>
		</footer>
	)
};

const mapStateToProps = ({ auth }) => {
	return {
		project: auth.projects.find(o => o.id === utils.getIdProject() && o.hospital.id === utils.getIdHospital())
	};
};

export default connect(mapStateToProps, {})(Footer);

