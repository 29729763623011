import { v4 as uuidv4 } from 'uuid';

export const loginData = (data) => ({
    tipo: "OPERACION_1-1",
    usuario: data.user,
    passwd: data.password,
});

export const responseLogin = ({data:{id, usuario, datos_usuario, token, roles}}) => ({
    id,
    user: {
        username: usuario,
        name: datos_usuario.nombre,
        surname: datos_usuario.apellidos,
    },
    token,
    projects: roles.map(transformRole),
});

export const responseToken = ({data:{ token }}) => ({
    token
});

export const responseUserMe = ({data:{ id, usuario, datos_usuario, roles }}) => ({
    id,
    user: {
        username: usuario,
        name: datos_usuario.nombre,
        surname: datos_usuario.apellidos,
    },
    projects: roles.map(transformRole),
});

export const responseUsersByProject = ({data:{ roles, usuarios}}) => ({
        id: uuidv4(),
          roles: roles.filter((rol) => rol.nombre_rol!="").map(transformRoleByProject),
          users: usuarios.map(transformUserByProject)

});

export const searchData = (data) => ({
    tipo: "OPERACION_13-2",
    nombre_usuario: data
});

export const addUserData = (data) => ({
    tipo: "OPERACION_13-4",
    nombre_usuario: data.name,
    rol: data.role
})

export const addExternalUserData = (data) => ({
    tipo: "OPERACION_13-5",
    nombre_usuario: data.name,
    rol: data.role
})

export const changePasswordData = (data) => ({
    tipo: "OPERACION_13-6",
    password: data.password
})

export const removeUserData = (userName) => ({
    tipo: "OPERACION_13-3",
    nombre_usuario: userName,
})


export const responseSearchUser = ({data}) => {
    return {users: data.usuarios.map(transformUserSearch)}
};

const transformUserSearch = ({cuenta_usuario, nombre_completo, email}) => ({
    username: cuenta_usuario,
    name: nombre_completo,
    email: email
});

const transformRoleByProject = ({id_rol, nombre_rol}) => ({
    id: uuidv4(),
    role: id_rol,
    name: nombre_rol
});

const transformUserByProject = ({nombre_usuario, rol}) => ({
    id: uuidv4(),
    name: nombre_usuario,
    role: rol
});

const transformRole = ({id_estudio, estudio, centro, id_centro, OC_DM, modulos, buscar, ...rest}) => {
    let roles =  ({
        id: id_estudio,
        name: estudio,
        hospital: {
            id: id_centro,
            name: centro
        },
        searchBar: buscar ?? true,
        menu: [
            ...modulos.map(transformModules),
        ],
        ...rest,
    });
    if(OC_DM){
        roles.menu.push(headerMenu('Paciente', true, (OC_DM ? OC_DM.map((o) => transformOC_DM(o, rest.usuario)) : [])))
    }
    return roles;
};

const headerMenu = (name, needPatient, submenu) => ({
    id: uuidv4(),
    name,
    type: 'header_menu',
    icon: undefined,
    url: undefined,
    parameter: undefined,
    modeView: 'default', // Enum ['default','tab','windows','pop up']
    needPatient: Boolean(needPatient),
    submenu,
});

const transformModules = ({id_modulo, nombre, ruta_logo, url, identificador, paciente_seleccionado, submenu}) => ({
    id: id_modulo ? id_modulo : uuidv4(),
    name: nombre,
    type: 'module',
    icon: ruta_logo,
    url: url+'/'+identificador,
    parameter: identificador,
    modeView: 'default', // Enum ['default','tab','windows','pop up']
    needPatient: paciente_seleccionado == "true",
    submenu: submenu?.map(transformModules) ?? [],

});

const transformOC_DM = ({id_formulario, nombre_evento, nombre_formulario, ruta_logo, url, real_url, formularios, externo}, user) => {
    const finalUrl = id_formulario ? '/'+id_formulario : ''
    const obj = {
        id: id_formulario ? id_formulario : uuidv4(),
        name: nombre_evento ? nombre_evento : nombre_formulario,
        type: 'OC_DM',
        icon: ruta_logo,
        url: url+finalUrl,
        parameter: id_formulario,
        modeView: externo ? 'tab' : 'default', // Enum ['default','tab','windows','pop up']
        submenu: formularios?.map(transformOC_DM) ?? [],
        needPatient: true,
    };

    if(url === '-1') {
        obj.modeView = 'popUp';
        obj.url = real_url;
    }

    return obj;
};
