import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {GET_DASHBOARD} from "../constants/Dashboard";
import DashboardService from "../api/dashboard";
import {getDashboardError, getDashboardSuccess} from "../actions/Dashboard";

export function* requestDashboard() {

	yield takeEvery(GET_DASHBOARD, function* ({payload, fake}) {
		try {
			const response = yield call(DashboardService.get, { payload, fake });
			if (response.message) {
				// TODO implement generic error dashboard
				// yield put(showErrorMessage(response.message));
			} else {
				yield put(getDashboardSuccess(response));
			}
		} catch (error) {
			// TODO implement generic error dashboard
			yield put(getDashboardError());
			// yield put(showErrorMessage('api.error.login'));
		}
	})
}

export default function* rootSaga() {
  yield all([
		fork(requestDashboard)
  ]);
}
