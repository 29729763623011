import {
  SEARCH,
  LOAD_PATIENT
} from '../constants/Patient';

const initState = {
  patient: {}
}

const patient = (state=initState, action) => {
  switch (action.type) {
    case LOAD_PATIENT:
      return {
        patient: action.payload
      }
    default:
      return state;
  }
}
export default patient;