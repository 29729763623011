import React, { useState } from 'react';
import {Table, Popconfirm, Form, Drawer, Button, message} from 'antd';

import {EditOutlined, PlusOutlined} from "@ant-design/icons";
import {transformField} from "../../../utils/TransformFormDashboard";
import {FormWithAction} from "../FormWithAction";
import {DrawerForm} from "./DrawerForm";
import {connect} from "react-redux";
import {selectFormRow} from "../../../services/actions/Form";
import FormService from "../../../services/api/form";
import utils from "../../../utils";


const EditableCell = ({
                        editing,
                        dataIndex,
                        title,
                        inputType,
                        options,
                        record,
                        index,
                        children,
                        ...restProps
                      }) => {

  return (
    <td {...restProps}>
      {editing ? (
        transformField({type: inputType, 'name': dataIndex, size: 24, condition: true, options: options}).getComponent()()
      ) : (
        children
      )}
    </td>
  );
};

const TableForm = ({id, title, columns, rows, fields, selectFormRow}) => {
  const [form] = Form.useForm();
  const [data, setData] = useState(rows);
  const [visible, setVisible] = useState(false);
  const [editingKey, setEditingKey] = useState('');

  const showDrawer = () => {
    setVisible(true)
  };

  const onClose = () => {
    setVisible(false)

  };
  const isEditing = record => record.key === editingKey;

  const edit = record => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  // const save = async key => {
  //   try {
  //     const row = await form.validateFields();
  //     const newData = [...data];
  //     const index = newData.findIndex(item => key === item.key);
  //
  //     if (index > -1) {
  //       const item = newData[index];
  //       newData.splice(index, 1, { ...item, ...row });
  //       setData(newData);
  //       setEditingKey('');
  //     } else {
  //       newData.push(row);
  //       setData(newData);
  //       setEditingKey('');
  //     }
  //
  //   } catch (errInfo) {
  //     console.log('Validate Failed:', errInfo);
  //   }
  // };

  const removeLine = async key => {
    const newData = [...data];
    const index = newData.findIndex(item => key === item.key);

    if(index != -1) {
      newData.splice(index, 1);
      setData(newData);
      setEditingKey('');

      FormService.save({
        id_form: utils.getGenericIdLocation(),
        id_patient: utils.getPatientLocation(),
        form: newData,
        idTable: id
      })
        .then(res => {
          message.success("Fila eliminada")
        })
    }

  }

  const saveFromDrawer = (row) => {
    const newData = [...data];
    const index = newData.findIndex(item => row.key === item.key);
    if (index > -1) {
      const item = newData[index];
      newData.splice(index, 1, { ...item, ...row });
      setData(newData);
    }
    else {
      row.key = newData.length;
      newData.push(row);
      setData(newData);
    }

    FormService.save({id_form: utils.getGenericIdLocation(), id_patient: utils.getPatientLocation(), form: newData, idTable: id})
      .then(res => {
        message.success("Guardado realizado")
      })

    console.log(newData)
  }

  // const actionButton = [
  //   {
  //     title: '',
  //     dataIndex: 'operation',
  //     render: (text, record) => {
  //       const editable = isEditing(record);
  //       return editable ? (
  //         <span>
  //           <a
  //             href="javascript:;"
  //             onClick={() => save(record.key)}
  //             style={{
  //               marginRight: 8,
  //             }}
  //           >
  //             Guardar
  //           </a>
  //           <Popconfirm title="¿Desea descartar los cambios?" onConfirm={() => cancel(record.key)}>
  //             <a>Cancelar</a>
  //           </Popconfirm>
  //         </span>
  //       ) : (
  //         <>
  //           <a disabled={editingKey !== ''} onClick={() => edit(record)}>
  //           <EditOutlined />
  //         </a>
  //           <a onClick={() => {
  //             selectFormRow(record);
  //             showDrawer()
  //           } }>
  //             <EditOutlined />
  //
  //           </a></>
  //
  //       );
  //     },
  //   }
  // ];
  const actionButton = [
    {
      title: '',
      dataIndex: 'operation',
      render: (text, record) => (
            <a onClick={() => {
              selectFormRow(record);
              showDrawer()
            } }>
              <EditOutlined />

            </a>
        )
    },
  ];

  const mergedColumns = columns.filter(col => (!col.hidden) && col.inputType!="line" ).map(col => {
    // if (!col.editable) {
    //   return col;
    // }
    return {
      ...col,
      onCell: record => ({
        record,
        inputType: col.inputType,
        dataIndex: col.dataIndex,
        title: col.title,
        // editing: isEditing(record),
        options: col.options
      }),
    };
  });
  return (
    <>
      <Form form={form} component={false}>
        <Table
          bordered
          dataSource={data}
          columns={mergedColumns.concat(actionButton)}
          rowClassName="editable-row"
          scroll={{ x: 1 }}
          size="small"
          pagination={{
            onChange: cancel,
          }}
        />

      </Form>
      <div>
        <Button type="primary" onClick={() => {showDrawer(); selectFormRow(null)}}>
          <PlusOutlined /> Nueva Fila
        </Button>
        <Drawer
          title={title}
          width={"85%"}
          onClose={onClose}
          visible={visible}
          bodyStyle={{ paddingBottom: 80, backgroundColor: "#fafafb" }}
        >
          <DrawerForm
            fields={fields}
            visible={visible}
            save={saveFromDrawer}
            removeLine={removeLine}
            onClose={onClose}
          />

        </Drawer>
      </div>
    </>
  );
};

export default connect(null, { selectFormRow })(TableForm)
