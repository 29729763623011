export const responseExportation = ({data}) => {
  const { arbol } = data;
  let result = arbol;
  return result.elementos.map(transformTreeElement);
}

const transformTreeElement = (element) => {
  return (
    {
      title: element.nombre,
      key: element.id ?? element.item_id,
      study_event_id: element.study_event_id,
      version_id: element.version_id,
      children: element.elementos != undefined ? element.elementos?.map(transformTreeElement) : []
    }
  )
}

export const requestExportData = (data) => {
  return {
    tipo: data.type == 'EXCEL' ? "OPERACION_Tree-1" : "OPERACION_Tree-1-"+data.type,
    sistema: "OC_DM",
    dataExport: data.selected.map(transformRequestElement)
  };
}

const transformRequestElement = (element) => {
  return {
    item_id: element.key,
    nombre: element.title,
    ...element
  }
}