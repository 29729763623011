import {Widget} from "./index";
import React from "react";
import StatisticWidget from "../../components/shared-components/StatisticWidget";

export class WidgetStatistic extends Widget {

  constructor(obj = {}) {
    super(obj);
    this.value = obj.value;
    this.subtitle = obj.subtitle;
    this.status = obj.status;
  }

  getComponent() {
    return () => (
      <StatisticWidget
        title={this.title}
        value={this.value}
        status={this.status}
        subtitle={this.subtitle}
      />
    );
  }

  transformToWidget = (data) => {
    this.title = data.title;
    this.type = data.type;
    this.size = data.size;
    this.value = data.value;
    this.subtitle = data.subtitle;
    this.status = data.status;
    return this;
  }
}