import {GET_CHAT, GET_CHAT_SUCCESS} from "../constants/Chat";


const initState = {
  chat: []
}

const chat = (state=initState, action) => {
  switch (action.type) {
    case GET_CHAT_SUCCESS:
      return {
        chat: action.payload
      }
    default:
      return state;
  }
}
export default chat;