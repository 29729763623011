import {Divider, message, Tooltip} from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import {ApiService, handlerError} from "../services/api/ApiService";
import Icon from "../components/util-components/Icon";
import * as AntdIcons from "@ant-design/icons";
import {transformField} from "./TransformFormDashboard";
import moment from "moment";

export const transformColumn = (headers) => {
  return headers?.map(o => {
    if(o.action)
      return {
        title: o.title,
        dataIndex: o.dataIndex,
        key: o.dataIndex,
        render: (data, record) => (
          <span>
            {data?.map(action => (<TransformAction action={action} record={record}/>))}
          </span>
        ),
      }
    if(o.children)
      return {
        title: o.title,
        children: transformColumn(o.children)
      }
    else
      return {
        title: o.title,
        dataIndex: o.dataIndex,
        key: o.dataIndex,
        fixed: o.fixed
      }

  })
}

// {
//   type: tipo de acción,
//   link: url destino
//   operation: opración API
//   name: nombre del botón
// }

const callApi = (action, record, history) => {
  ApiService.post('', {tipo: action.operation, data: record})
    .then(res => {
      message.success({content: res.data.data?.text ?? 'Acción realizada', duration: 2});
      if(action.link)
        history.push(action.link)
    })
    .catch(handlerError);
}


const TransformAction = ({action, record}) => {
  let history = useHistory();
  switch (action.type) {
    case "operation":
      return (
        <Tooltip title={action.help}>
                <span>
                  <a onClick={() => callApi(action, record, history)}>
                    {action.icon ? <Icon type={AntdIcons[action.icon]}
                                         style={{color: action.color, cursor: "pointer", fontSize: "22px", marginRight: "5px"}}/> : <>{action.name}<Divider type="vertical"/></>}
                  </a>
                </span>
        </Tooltip>
      )
    case "new-tab":
      return (
        <Tooltip title={action.help}>
                <span>
                  <a onClick={() => window.open(action.link, "_blank")}>
                    {action.icon ? <Icon type={AntdIcons[action.icon]}
                                         style={{color: action.color, cursor: "pointer", fontSize: "22px", marginRight: "5px"}}/> : <>{action.name}<Divider type="vertical"/></>}
                  </a>
                </span>
        </Tooltip>
      )
    default:
      return  <a>No Implementado</a>


  }

}


// Table-Form

export const transformSectionForm = (section) => {
  section.fields = section.fields.map(transformField);
  return section;
}

export const transformFieldToColumn = (field) => {

  return {
        title: field.label,
        dataIndex: field.name,
        key: field.name,
        inputType: field.type,
        editable: true,
        options: field.options,
        hidden: field.t_hide,
        render: (text) => (
          <span>
           {field.type === 'date' ?  moment(text, 'YYYY-MM-DD').isValid() ? moment(text, 'YYYY-MM-DD').format('DD/MM/YYYY') : '' :
             field.type === 'remotesearch' ? textForRemoteSearch(text) : text}
          </span>
        ),
      }
}


const textForRemoteSearch = (value) => {
  let result = "";
  if(Array.isArray(value))
    value.map((o, index) => {
      result += o.label+ ", ";
    })
  else
    result = value;
  return result
}
export const transformColumnForm = (column, index) => {
  column.key = index;
  return column;
}