import {Widget} from "./index";
import React from "react";
import GoalWidget from "../../components/shared-components/GoalWidget";

export class WidgetGoal extends Widget {

  constructor(obj = {}) {
    super(obj);

    this.value = obj.value;
    this.subtitle = obj.subtitle;
  }

  getComponent() {
    return () => (
      <GoalWidget
        title={this.title}
        value={this.value}
        subtitle={this.subtitle}
        // extra={<Button type="primary" onClick={() => pushRoute()}>Learn More</Button>}
      />
    );
  }

  transformToWidget = (data) => {
    this.title = data.title;
    this.type = data.type;
    this.size = data.size;
    this.value = data.value;
    this.subtitle = data.subtitle;
    return this;
  }
}