import React from 'react'
import {Col, Form, InputNumber} from "antd";
import {Field} from "./index";
import Utils from "../../utils";
import {FieldBase} from "./components/FieldBase";

export class FieldNumber extends Field {

  constructor(obj={}) {
    super(obj);
    this.min = obj.min ?? undefined;
    this.max = obj.max ?? undefined;
  }

  getComponent (form) {
    return () => (
      <FieldBase
        condition={this.condition}
        form={form}
        size={this.size}
        label={this.label}
        name={this.name}
        value={this.value}
        help={this.help}
        min={this.min}
        max={this.max}
      >

        <InputNumber style={{ width: '100%' }} disabled={this.disabled}/>

      </FieldBase>);
  }

  transformToField = (data) => {
    console.log(data)
    this.name = data.name;
    this.label = data.label;
    this.type = data.type;
    this.size = data.size ?? this.size;
    this.min = data.min ?? this.min;
    this.max = data.max ?? this.max;
    this.value = data.value
    this.disabled = data.disabled ?? false;
    this.condition = data.condition ?? true;
    this.help = data.help;

    return this;
  }
}